@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #000;
  font-family: source-han-serif-japanese, serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
}

.inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 0 20px;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 75px;
}

@media only screen and (max-width: 830px) {
  #contents_wrap {
    margin-bottom: 25px;
  }
}

#contents {
  width: 100%;
  max-width: 930px;
}

@media only screen and (max-width: 830px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 830px) {
  header#global_header {
    padding-top: 68px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed;
  z-index: 11;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
}

#hednav {
  width: 37%;
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #hednav {
    width: 60%;
  }
}

.chuumon {
  background: #a9a9a9;
}

.chuumon i {
  padding-left: 7px;
}

.chuumon a {
  color: #fff;
  padding: 35px;
  display: block;
}

@media only screen and (max-width: 640px) {
  .chuumon a {
    padding: 16px;
  }
}

.saichuumon {
  background: #a9a9a9;
  border-left: 1px solid #fff;
}

.saichuumon i {
  padding-left: 7px;
}

.saichuumon a {
  color: #fff;
  padding: 35px;
  display: block;
}

@media only screen and (max-width: 640px) {
  .saichuumon a {
    padding: 16px;
  }
}

#header {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background: #fff;
}

@media only screen and (max-width: 640px) {
  #header {
    box-shadow: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header {
    box-shadow: none;
  }
}

#header #hedwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 830px) {
  #header #hedwrap {
    padding: 30px;
    display: block;
  }
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #header #hedwrap {
    padding: 50px;
  }
}

#header #hedwrap .flxL {
  width: 20%;
}

@media only screen and (max-width: 830px) {
  #header #hedwrap .flxL {
    width: 100%;
    margin: 0 auto 0;
  }
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #header #hedwrap .flxL {
    width: 32%;
  }
}

#header #hedwrap .flxL #siteID {
  font-size: 24px;
}

#header #hedwrap .flxL #siteID a {
  display: block;
  color: #333;
}

#header #hedwrap .flxL #siteID img {
  width: 100% !important;
}

@media only screen and (max-width: 830px) {
  #header #hedwrap .flxL #siteID img {
    max-width: 100%;
  }
}

#header #hedwrap .flxR {
  width: 43%;
}

@media only screen and (max-width: 640px) {
  #header #hedwrap .flxR {
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header #hedwrap .flxR {
    width: 58%;
  }
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #header #hedwrap .flxR {
    width: 0;
  }
}

#header #hedwrap .flxR .subnav {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 830px) {
  #header #hedwrap .flxR .subnav {
    margin-top: 10px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100% !important;
    justify-content: space-between;
  }
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #header #hedwrap .flxR .subnav {
    margin-top: 10px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100% !important;
    justify-content: space-between;
  }
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #header #hedwrap .flxR .subnav .left {
    width: 35% !important;
  }
}

@media only screen and (max-width: 640px) {
  #header #hedwrap .flxR .subnav .left {
    width: 35% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header #hedwrap .flxR .subnav .left {
    width: 52% !important;
  }
}

#header #hedwrap .flxR .subnav .left .tel2 {
  color: #333;
  font-size: 26px;
  font-family: "Times New Roman";
  padding-right: 5px;
}

#header #hedwrap .flxR .subnav .left .tel2 img {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #header #hedwrap .flxR .subnav .left .tel2 {
    margin-right: 0;
    background: rgba(255, 255, 255, 0.5);
  }
  #header #hedwrap .flxR .subnav .left .tel2 a {
    padding: 14px !important;
    display: block;
    color: #333 !important;
  }
  #header #hedwrap .flxR .subnav .left .tel2 span {
    display: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header #hedwrap .flxR .subnav .left .tel2 {
    padding: 33px;
    margin-right: 0;
    background: rgba(255, 255, 255, 0.5);
  }
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #header #hedwrap .flxR .subnav .left .tel2 {
    margin-right: 0;
    background: rgba(255, 255, 255, 0.5);
    padding: 33px;
  }
}

#header #hedwrap .flxR .subnav .left .tel2 a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

#header #hedwrap .flxR .subnav .left .tel2 i {
  font-size: 20px;
  font-weight: 300;
  margin-right: 7px;
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #header #hedwrap .flxR .subnav .right {
    width: 37% !important;
  }
}

@media only screen and (max-width: 640px) {
  #header #hedwrap .flxR .subnav .right {
    width: 37% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header #hedwrap .flxR .subnav .right {
    width: 25% !important;
  }
}

#header #hedwrap .flxR .subnav .right .cont {
  color: #fff;
  background: #1b4996;
  letter-spacing: 0.1em;
  transition: 1s;
}

#header #hedwrap .flxR .subnav .right .cont:hover {
  background: #2a5cae;
}

#header #hedwrap .flxR .subnav .right .cont a {
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 25px;
}

#header #hedwrap .flxR .subnav .right .cont i {
  font-size: 20px;
  font-weight: 300;
  margin-right: 7px;
}

#header #hedwrap .flxR .subnav .right .cont span {
  font-size: 16px;
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #header #hedwrap .flxR .subnav .center {
    width: 37% !important;
  }
}

@media only screen and (max-width: 640px) {
  #header #hedwrap .flxR .subnav .center {
    width: 37% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header #hedwrap .flxR .subnav .center {
    width: 26% !important;
  }
}

#nav_global {
  z-index: 10;
  position: relative;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul li {
  position: relative;
}

#nav_global ul li a {
  line-height: 1;
  color: #333 !important;
  display: block;
  font-size: 16px;
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 830px) {
  .global-menu-btn {
    z-index: 11;
    display: block;
    position: absolute;
    left: 15px;
    top: 15px;
    color: #333;
    line-height: 1;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 830px) {
  .global-menu-close-btn {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 830px) {
  #hednav {
    justify-content: center;
    height: 68px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    margin: 0;
    padding: 10px 0;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #fff;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info span {
    line-height: 1;
    display: block;
    margin-top: 10px;
  }
  #hednav .wrap .navwrap #hed_info .store1 p {
    background: #892141;
  }
  #hednav .wrap .navwrap #hed_info .store2 p {
    border-left: 1px solid #fff;
    background: #892141;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 50%;
    margin: 0 !important;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    padding: 10px 0;
    font-weight: 300;
    font-size: 0.5rem !important;
    border: 0 !important;
    color: #fff !important;
    transition: 1s;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-weight: 300;
    font-size: 1.5rem !important;
    margin: 0 0 5px 0 !important;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: solid 1px #ddd;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 70px;
    line-height: 1;
    height: 100%;
    font-weight: 300;
    font-size: 0.8rem !important;
  }
  #hednav .wrap .navwrap #nav_global ul li a::before {
    display: block;
    position: absolute;
    left: 15px;
    z-index: 1;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    font-family: channel-icons;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 pro";
    color: #333;
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(1) a::before {
    content: "\f015";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(2) a::before {
    left: 13px;
    content: "\f085";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(3) a::before {
    left: 19px;
    content: "\f02f";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(4) a::before {
    left: 20px;
    content: "\f518";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(5) a::before {
    left: 23px;
    content: "\f1ad";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(6) a::before {
    left: 17px;
    content: "\f2b5";
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#mainArea {
  position: relative;
  z-index: 0;
  width: 100%;
}

#mainArea .maintxt {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#mainArea .maintxt h1 {
  color: #fff;
  font-weight: bold;
  line-height: 1.7em;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  font-size: 70px;
  font-size: 9.11458vw;
}

@media only screen and (min-width: 1600px) {
  #mainArea .maintxt h1 {
    font-size: 70px;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #mainArea .maintxt h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea .maintxt h1 {
    font-size: 35px;
  }
}

#mainArea .maintxt h1 span {
  display: block;
}

#mainArea img {
  width: 130% !important;
  height: 500px !important;
  object-fit: cover;
}

@media only screen and (max-width: 640px) {
  #mainArea img {
    width: 150% !important;
    height: 255px !important;
    object-fit: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #mainArea img {
    width: 150% !important;
    height: 340px !important;
    object-fit: cover;
  }
}

#local-keyvisual {
  position: relative;
  background-position: center;
}

#local-keyvisual h1 {
  z-index: 1;
  position: relative;
  color: #fff;
  font-weight: bold;
  line-height: 1.7em;
  text-align: center;
  font-size: 40px;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #local-keyvisual h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 35px;
  }
}

#local-keyvisual h1 span {
  background-position: center;
  background-size: cover;
  background: rgba(51, 51, 51, 0.1);
  padding: 150px 0;
  display: block;
}

@media only screen and (max-width: 830px) {
  #local-keyvisual h1 span {
    padding: 100px 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.pan1 {
  margin: 10px 0;
}

@media only screen and (max-width: 830px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 640px) {
  .pcnone {
    display: block;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  background: url(../images/common/hed_nav.jpg);
  color: #333;
  margin-top: 0;
  font-family: source-han-serif-japanese, serif;
  font-style: normal;
  font-weight: bold;
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  footer#global_footer {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 830px) {
  footer#global_footer {
    padding-bottom: 100px;
  }
}

footer#global_footer a {
  color: #333;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: underline;
}

footer#global_footer #nav_footer {
  margin-bottom: 70px;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 16px;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.ftlogo {
  font-size: 26px;
}

.ftwrap {
  line-height: 2;
}

.h2_01 {
  line-height: 1;
}

.h2_01 .top {
  font-size: 22px;
}

.h2_01 h2 {
  font-size: 30px;
}

@media only screen and (max-width: 640px) {
  .h2_01 h2 {
    font-size: 30px;
  }
}

.h2_01 span {
  color: #565656;
}

.news2 {
  height: 200px;
  overflow-y: scroll;
}

.news2 li {
  padding: 20px 0;
  border-bottom: 1px dashed #ccc;
}

.news2 li span {
  display: block;
}

.news2wrap {
  padding: 20px;
}

.sec_01 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 830px) {
  .sec_01 {
    display: block;
  }
  .sec_01 .flxL {
    width: 100% !important;
  }
  .sec_01 .flxR {
    width: 100% !important;
  }
}

.sec_01 .flxL {
  width: 61%;
  display: flex;
}

.sec_01 .flxL .imgwrap {
  width: 56%;
}

.sec_01 .flxL .imgwrap img {
  width: 100% !important;
}

.sec_01 .flxL .imgwrap .img1 {
  margin-top: 30px;
}

@media only screen and (max-width: 640px) {
  .sec_01 .flxL .imgwrap .img1 {
    margin-top: 10px;
  }
}

.sec_01 .flxL .img2 {
  width: 43%;
  margin-left: 30px;
}

@media only screen and (max-width: 640px) {
  .sec_01 .flxL .img2 {
    margin-left: 10px;
  }
}

.sec_01 .flxL .img2 img {
  width: 100% !important;
}

.sec_01 .flxR {
  width: 31%;
}

.sec_02wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .sec_02wrap {
    display: block;
  }
  .sec_02wrap .sec_02 {
    width: 100% !important;
  }
}

.sec_02wrap .sec_02 {
  width: 48%;
}

.sec_02wrap > div:first-child {
  display: none;
}

.sec_02wrap > div:last-child {
  display: none;
}

.sec_03wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 830px) {
  .sec_03wrap {
    display: block;
  }
  .sec_03wrap .sec_03 {
    width: 100% !important;
  }
}

.sec_03wrap .sec_03 {
  width: 48%;
}

.sec_03wrap > div:first-child {
  display: none;
}

.sec_03wrap > div:last-child {
  display: none;
}

.newswrap {
  padding: 20px 0;
}

.newswrap .news {
  height: 300px;
  overflow-y: scroll;
}

.newswrap .news dt {
  border: none !important;
  display: block !important;
  width: 100% !important;
}

.newswrap .news dd {
  border: none !important;
  width: 100% !important;
}

.newswrap .news dl {
  border-bottom: 1px dashed #ccc;
  display: block !important;
}

.instabox {
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .instabox li {
    width: 46% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .instabox li {
    width: 30% !important;
  }
  .instabox li img {
    width: 100% !important;
  }
}

.instabox li {
  width: 27%;
  margin: 10px;
}

.instagram {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 280px;
  overflow-y: hidden;
}

@media only screen and (max-width: 830px) {
  .instagram {
    height: 500px;
  }
}

.midashibox {
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .midashibox {
    display: block !important;
  }
  .midashibox .box {
    width: 100% !important;
    margin-top: 50px;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .midashibox .box {
    width: 48% !important;
    margin-top: 50px;
  }
  .midashibox .box img {
    width: 100% !important;
  }
}

.midashibox .box {
  width: 25%;
}

.midashibox .box > div {
  text-align: center;
  padding: 10px 0;
  font-size: 18px;
}

.btn a {
  color: #892141;
  padding: 25px;
  display: block;
  border: solid 1px #892141;
}

.btn i {
  margin-left: 7px;
}

.btn02 a {
  color: #fff;
  padding: 25px;
  display: block;
  background: #892141;
}

.btn02 i {
  margin-left: 7px;
}

.btn03 {
  border: 1px solid #892141;
  padding: 25px;
  color: #892141;
}

.btn03 a {
  color: #892141;
  padding: 25px;
  display: block;
}

.btnwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .btnwrap {
    display: block;
  }
  .btnwrap .btn02 {
    width: 100% !important;
  }
}

.btnwrap .btn02 {
  width: 48%;
}

.btnwrap a {
  padding: 30px;
  display: block;
}

.btnwrap > div:first-child {
  display: none;
}

.btnwrap > div:last-child {
  display: none;
}

.btnwrap2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .btnwrap2 {
    display: block;
  }
  .btnwrap2 .btn03 {
    width: 100% !important;
    padding: 0;
  }
  .btnwrap2 .fax {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .btnwrap2 .btn03 {
    padding: 25px;
  }
}

.btnwrap2 .btn03 {
  width: 48%;
  font-family: "Times New Roman";
  font-size: 26px;
}

.btnwrap2 .fax {
  width: 48%;
  font-size: 18px;
}

.btnwrap2 .fax i {
  margin-right: 7px;
  font-size: 20px;
}

.btnwrap2 a {
  padding: 25px;
  display: block;
}

/* box */
.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

.h3_01 h3 {
  font-size: 26px;
  font-weight: bold;
}

.h3_02 h3 {
  font-size: 23px;
  color: #892141;
  border-bottom: 1px dotted #892141;
  padding: 10px 0;
}

.sec_04 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 830px) {
  .sec_04 {
    display: block;
  }
  .sec_04 .flxL {
    width: 100% !important;
  }
  .sec_04 .flxR {
    width: 100% !important;
  }
}

.sec_04 .flxL {
  width: 60%;
}

.sec_04 .flxL img {
  width: 100% !important;
}

.sec_04 .flxR {
  width: 35%;
}

.sec_05 .img_list .box {
  margin: 10px;
}

@media only screen and (max-width: 640px) {
  .sec_05 .img_list .box img {
    width: 250px !important;
    height: 250px !important;
    object-fit: cover;
  }
}

.sec_06wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .sec_06wrap {
    display: block;
  }
  .sec_06wrap .sec_06 {
    width: 100% !important;
  }
}

.sec_06wrap .sec_06 {
  width: 48%;
}

.sec_06wrap .sec_06 img {
  width: 400px !important;
  height: auto !important;
  object-fit: cover;
}

.sec_07 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.sec_07 .flxL {
  width: 48%;
}

.sec_07 .flxL .midashi {
  font-size: 20px;
  background: #892141;
  color: #fff;
  padding: 10px 0;
}

.sec_07 .flxR {
  width: 48%;
}

.sec_07 .flxR .midashi {
  font-size: 20px;
  background: #892141;
  color: #fff;
  padding: 10px 0;
}

.sec_07 .table_paper td {
  border: none !important;
  padding: 5px !important;
}

.sec_07 .table_paper article > div {
  padding: 10px;
  font-size: 18px;
}

section div.form_wrap dl dd > div {
  line-height: 1.2;
}

.select {
  display: flex;
}

.table_01 td {
  padding: 3px !important;
}

.table_01 .td_top {
  border-top: 3px solid #ccc !important;
  border-left: 3px solid #ccc !important;
  border-right: 3px solid #ccc !important;
}

.table_01 .td_bottom {
  border-left: 3px solid #ccc !important;
  border-right: 3px solid #ccc !important;
  border-bottom: 3px solid #ccc !important;
}

.kikai_table td {
  border: none !important;
}

.gmap iframe {
  width: 100%;
}

.kikaibox {
  justify-content: space-between;
}

.kikaibox .box {
  width: 28%;
}

@media only screen and (max-width: 640px) {
  .kikaibox .box {
    width: 48%;
  }
}

.kikaibox .box img {
  width: 100% !important;
}

.kikaibox .box > div {
  text-align: center;
  padding: 10px;
  font-size: 20px;
}

@media only screen and (max-width: 640px) {
  .meishibox .box {
    width: 48% !important;
  }
}

.meishibox .box {
  width: 23%;
  margin: 1%;
}

.meishibox .box img {
  width: 100% !important;
}

.meishibox .box > div {
  text-align: center;
}

.ancbtn a {
  color: #fff;
  padding: 20px !important;
  display: block;
}

.ancbtn a:hover {
  text-decoration: underline;
}

.btnwrap3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 830px) {
  .btnwrap3 {
    display: block;
  }
  .btnwrap3 .btn {
    width: 100% !important;
  }
  .btnwrap3 .btn03 {
    width: 100% !important;
  }
}

.btnwrap3 .btn {
  width: 48%;
  font-size: 20px;
}

.btnwrap3 .btn span {
  font-size: 16px;
}

.btnwrap3 .btn03 {
  width: 48%;
  padding: 14px;
  font-size: 20px;
}

.btnwrap4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 830px) {
  .btnwrap4 {
    display: block;
  }
  .btnwrap4 .btn {
    width: 100% !important;
  }
  .btnwrap4 .btn03 {
    width: 100% !important;
  }
}

.btnwrap4 .btn {
  width: 48%;
  font-size: 20px;
}

.btnwrap4 .btn span {
  font-size: 16px;
}

.btnwrap4 .btn03 {
  width: 48%;
  font-size: 20px;
  padding: 0;
}

.cont_tel {
  font-size: 40px;
  font-weight: bold;
  font-family: "Times New Roman";
}

.cont_tel i {
  margin-right: 10px;
}

.cont_tel a {
  color: #333;
}

.time {
  font-size: 14px;
  color: #565656;
}

.font_print {
  font-size: 14px;
}

.font_print dt {
  display: block !important;
}

.font_print .textarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.font_print .textarea textarea {
  width: 60% !important;
  padding: 0 !important;
  min-height: 42px !important;
}

.font_print .select {
  display: block !important;
}

.font_print .select > div {
  display: block !important;
}

.print_form {
  font-size: 12px;
}

.print_form dt {
  display: block !important;
  padding: 5px !important;
}

.print_form dd {
  padding: 5px !important;
}

.print_form .textarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.print_form .textarea textarea {
  width: 60% !important;
  padding: 0 !important;
  min-height: 42px !important;
}

.contact_form {
  font-size: 12px;
}

.contact_form dt {
  display: block !important;
  padding: 5px !important;
}

.contact_form dd {
  padding: 5px !important;
}

.contact_form .textarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact_form .textarea textarea {
  width: 60% !important;
  padding: 0 !important;
  min-height: 42px !important;
}

.info_form {
  font-size: 12px;
}

.info_form dt {
  display: block !important;
  padding: 5px !important;
}

.info_form dd {
  padding: 5px !important;
}

.info_form .textarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info_form .textarea textarea {
  width: 60% !important;
  padding: 0 !important;
  min-height: 42px !important;
}

.order_form {
  font-size: 12px;
}

.order_form dt {
  display: block !important;
  padding: 5px !important;
}

.order_form dd {
  padding: 5px !important;
}

.order_form .select {
  display: block !important;
}

.order_form .select > div {
  display: block !important;
}

.order_form .textarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order_form .textarea textarea {
  width: 60% !important;
  padding: 0 !important;
  min-height: 42px !important;
}

.reorder_form {
  font-size: 12px;
}

.reorder_form dt {
  display: block !important;
  padding: 5px !important;
}

.reorder_form dd {
  padding: 5px !important;
}

.reorder_form .textarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reorder_form .textarea textarea {
  width: 60% !important;
  padding: 0 !important;
  min-height: 42px !important;
}

input[type="text"], input[type="password"], textarea {
  padding: 0 !important;
}

/* title */
.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  text-align: center;
  padding: 10px 0;
}

.bg_100per_wrap {
  position: relative;
  padding: 100px 0 !important;
}

@media only screen and (max-width: 830px) {
  .bg_100per_wrap {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

#anc01 {
  display: block;
  margin-top: -100px !important;
  padding-top: 100px !important;
}

#anc02 {
  margin-top: -100px !important;
  padding-top: 100px !important;
}

#anc03 {
  margin-top: -100px !important;
  padding-top: 100px !important;
}

#anc04 {
  margin-top: -100px !important;
  padding-top: 100px !important;
}

#anc05 {
  margin-top: -100px !important;
  padding-top: 100px !important;
}

#anc06 {
  margin-top: -100px !important;
  padding-top: 100px !important;
}
